import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const StoneFruit = () => {
  return (
    <PageWrapper pageTitle="StoneFruit">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-stone-fruit"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">StoneFruit</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/stone-fruit-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        {/* <h4 className="title">It’s very important to touch the right points.</h4> */}
                        <ul className="liststyle">
                          <li>
                            Turkiye, which is the second in the world with exports of 170 million dollars in peach and
                            nectarine exports in 2021, aims to be the world leader.
                          </li>
                          <li>The highest amount of exports in Turkiye the drupe fruits are peach and nectarine.</li>
                          <li>
                            Turkiye exports pomegranates, peaches, nectarines, tangerines, and quinces worth 800 million
                            dollars annually.
                          </li>
                          <li>
                            Dried apricots are exported to 115 countries, and dried apricot exports to Europe reached
                            154 million dollars.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default StoneFruit;
