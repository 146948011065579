import React from 'react';
import PageWrapper from '../../components/PageWrapper';

function OnionGrowers() {
  return (
    <PageWrapper pageTitle="Growing Onion in Turkiye">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-turkish-onion-growers"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Growing Onion in Turkiye</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-blog-details pt--70 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <h3>Turkish onion growers are competing with EU growers in quality aspects.</h3>
                  <p>
                    Turkiye is one of the largest producers of onions in the world and is known for producing
                    high-quality onions with a strong, sweet flavor. Here are a few key points to consider regarding
                    onion production in Turkiye:
                  </p>
                  <div className="row sercice-details-content pb--30 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/turkish-onion-growers-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <ul className="liststyle">
                          <li>
                            Variety: Turkiye grows a variety of onion types, including red, yellow, and white onions, to
                            meet the demands of both domestic and international markets. Hybrid varieties increase
                            market share and are preferred by Turkish growers.
                          </li>
                          <li>
                            Production methods: Turkish farmers use modern techniques and technologies to produce
                            onions, including modern spray programs, drip irrigation and greenhouses, which allow for
                            consistent and high-quality yields.
                          </li>
                          <li>
                            Sustainability: Many Turkish onion producers prioritize sustainability in their production
                            methods, including using eco-friendly fertilizers and reducing water waste through efficient
                            irrigation systems.
                          </li>
                          <li>
                            Export markets: Turkiye exports a significant amount of onions to countries in Europe, the
                            Middle East, and North Africa, where they are highly valued for their quality and flavor.
                          </li>
                          <li>
                            Quality standards: The Turkish government has established quality standards for onion
                            production and exports, which aim to ensure that Turkish onions meet international standards
                            for food safety, health, and environmental protection.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br />
                  <p>
                    Overall, onion production in Turkiye is characterized by high quality and sustainability, as well as
                    a focus on meeting the needs of both domestic and international markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default OnionGrowers;
