import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
  return <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>;
};
const ContactForm = () => {
  const [result, showresult] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
      )
      .then(
        (result) => {
          // console.log(result, result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form
      ref={form}
      action=""
      onSubmit={sendEmail}
    >
      <div className="rn-form-group">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          required
        />
      </div>

      <div className="rn-form-group">
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          required
        />
      </div>

      <div className="rn-form-group">
        <textarea
          name="message"
          placeholder="Your Message"
          required
        ></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
};
export default ContactForm;
