import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Organic = () => {
  return (
    <PageWrapper pageTitle="Organic">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-organic"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Organic</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30 pb--50">
                        <h4 className="title">Love yourself, Love the world, Go Organic</h4>
                        <p>
                          Using organic pesticide will not harm plants they provide more strength and without any side
                          effects. Organic products have structurally fewer residues than on conventional products. We
                          in Gretra develop our Organic portfolio day by day for having more solutions in your healthy
                          eatings.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/products/agro-inputs/organic/organic-2.jpg"
                          alt="Organic Farming"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Organic;
