import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiSprayCan, BiDna, BiLayer } from 'react-icons/bi';
import { GiFertilizerBag, GiFruitBowl, GiSnowflake2, GiOpenedFoodCan, GiPeanut, GiCoolSpices } from 'react-icons/gi';
import { FaLeaf, FaSeedling } from 'react-icons/fa';

export const productList = [
  {
    icon: <FaSeedling />,
    title: 'Agro Inputs',
    path: '/agro-inputs',
    description: '',
  },
  {
    icon: <BiLayer />,
    title: 'Taste categories',
    path: '/taste-categories',
    description: '',
  },
];

export const agroInputList = [
  {
    icon: <FaSeedling />,
    title: 'Seeds',
    path: '/seeds',
    description: '.',
  },
  {
    icon: <GiFertilizerBag />,
    title: 'Fertilizers',
    path: '/fertilizers',
    description: '',
  },
  {
    icon: <BiSprayCan />,
    title: 'Pesticides',
    path: '/pesticides',
    description: '',
  },
  {
    icon: <FaLeaf />,
    title: 'Organic',
    path: '/organic',
    description: '',
  },
  {
    icon: <BiDna />,
    title: 'Biological',
    path: '/biological',
    description: '',
  },
  {
    icon: <AiOutlineCloudUpload />,
    title: 'Digital Solutions',
    path: '/digital-solutions',
    description: '',
  },
];

export const tasteCategoryList = [
  {
    icon: <GiFruitBowl />,
    title: 'Fresh Category',
    path: '/fresh-category',
    description: '',
  },
  {
    icon: <GiSnowflake2 />,
    title: 'Frozen & Dried Category',
    path: '/frozen-dried-category',
    description: '',
  },
  {
    icon: <GiOpenedFoodCan />,
    title: 'Process Category',
    path: '/process-category',
    description: '',
  },
  {
    icon: <GiPeanut />,
    title: 'Nuts Category',
    path: '/nuts-category',
    description: '',
  },
  {
    icon: <GiCoolSpices />,
    title: 'Spice Category',
    path: '/spice-category',
    description: '',
  },
];

const freshCategoryData = [
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Apple.png',
    title: 'Apple',
    category: 'Fresh',
    path: 'products/apple',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Apricot.png',
    title: 'Apricot',
    category: 'Fresh',
    path: 'products/apricot',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Artichoke.png',
    title: 'Artichoke',
    category: 'Fresh',
    path: 'products/artichoke',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Asparagus.png',
    title: 'Asparagus',
    category: 'Fresh',
    path: 'products/asparagus',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Avocado.png',
    title: 'Avocado',
    category: 'Fresh',
    path: 'products/avocado',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Blueberry.png',
    title: 'Blueberry',
    category: 'Fresh',
    path: 'products/blueberry',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Cucumber.png',
    title: 'Cucumber',
    category: 'Fresh',
    path: 'products/cucumber',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Carrot.png',
    title: 'Carrot',
    category: 'Fresh',
    path: 'products/carrot',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Citrus.png',
    title: 'Citrus',
    category: 'Fresh',
    path: 'products/citrus',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Dragon Fruit.png',
    title: 'Dragon Fruit',
    category: 'Fresh',
    path: 'products/dragon-fruit',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Eggplant.png',
    title: 'Eggplant',
    category: 'Fresh',
    path: 'products/eggplant',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Fig.png',
    title: 'Fig',
    category: 'Fresh',
    path: 'products/fig',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Kiwi.png',
    title: 'Kiwi',
    category: 'Fresh',
    path: 'products/kiwi',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Mulberry.png',
    title: 'Mulberry',
    category: 'Fresh',
    path: 'products/mulberry',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Onion.png',
    title: 'Onion',
    category: 'Fresh',
    path: 'products/onion',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Passion Fruit.png',
    title: 'Passion Fruit',
    category: 'Fresh',
    path: 'products/passion-fruit',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Peach.png',
    title: 'Peach',
    category: 'Fresh',
    path: 'products/peach',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Pear.png',
    title: 'Pear',
    category: 'Fresh',
    path: 'products/pear',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Pepper.png',
    title: 'Pepper',
    category: 'Fresh',
    path: 'products/pepper',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Plum.png',
    title: 'Plum',
    category: 'Fresh',
    path: 'products/plum',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Pomegranate.png',
    title: 'Pomegranate',
    category: 'Fresh',
    path: 'products/pomegranate',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Quince.png',
    title: 'Quince',
    category: 'Fresh',
    path: 'products/quince',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Radish.png',
    title: 'Radish',
    category: 'Fresh',
    path: 'products/radish',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Raspberry.png',
    title: 'Raspberry',
    category: 'Fresh',
    path: 'products/raspberry',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Salads.png',
    title: 'Salads',
    category: 'Fresh',
    path: 'products/salads',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Strawberry.png',
    title: 'Strawberry',
    category: 'Fresh',
    path: 'products/strawberry',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Sweet Cherry.png',
    title: 'Sweet Cherry',
    category: 'Fresh',
    path: 'products/sweet-cherry',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Tomatoes.png',
    title: 'Tomato',
    category: 'Fresh',
    path: 'products/tomato',
  },
  {
    src: '/assets/images/publish/products/taste-categories/fresh-category/Watermelon.png',
    title: 'Watermelon',
    category: 'Fresh',
    path: 'products/watermelon',
  },
];

const frozenDriedCategoryData = [
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Sun Dried Tomatoes.png',
    title: 'Tomatoes',
    category: 'Dried',
    path: 'products/sun-dried-tomatoes',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Dried Pepper.png',
    title: 'Pepper',
    category: 'Dried & Frozen',
    path: 'products/dried-pepper',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Dried Eggplant.png',
    title: 'Eggplant',
    category: 'Dried',
    path: 'products/dried-eggplant',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Grape.png',
    title: 'Raisin',
    category: 'Dried',
    path: 'products/raisin',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Fig.png',
    title: 'Fig',
    category: 'Dried',
    path: 'products/dried-fig',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Apricot.png',
    title: 'Apricot',
    category: 'Dried',
    path: 'products/dried-apricot',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Plum.png',
    title: 'Plum',
    category: 'Dried',
    path: 'products/dried-plum',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Apple.png',
    title: 'Apple',
    category: 'Dried',
    path: 'products/dried-apple',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/citrus.png',
    title: 'Citrus',
    category: 'Dried',
    path: 'products/dried-citrus',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Berries.png',
    title: 'Berries',
    category: 'Dried & Frozen',
    path: 'products/frozen-berries',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/Green Peas.png',
    title: 'Green Peas',
    category: 'Dried & Frozen',
    path: 'products/frozen-green-peas',
  },
  {
    src: '/assets/images/publish/products/taste-categories/frozen-dried-category/French Fries.png',
    title: 'French Fries',
    category: 'Frozen',
    path: 'products/french-fries',
  },
];
const processCategoryData = [
  {
    src: '/assets/images/publish/products/taste-categories/process-category/canned-vagetables.png',
    title: 'Canned Vegetables',
    category: 'Spice',
    path: 'products/canned-vegetables',
  },
  {
    src: '/assets/images/publish/products/taste-categories/process-category/cut-packed-vegetables.png',
    title: 'Cut & Packed Vegetables',
    category: 'Processed',
    path: 'products/cut-packed-vegetables',
  },
  {
    src: '/assets/images/publish/products/taste-categories/process-category/tomato-paste.png',
    title: 'Tomato Paste',
    category: 'Processed',
    path: 'products/tomato-paste',
  },
];
const nutsCategoryData = [
  {
    src: '/assets/images/publish/products/taste-categories/nuts-category/Almond.png',
    title: 'Almond',
    category: 'Nuts',
    path: 'products/almond',
  },
  {
    src: '/assets/images/publish/products/taste-categories/nuts-category/Pistachio.png',
    title: 'Pistachio',
    category: 'Nuts',
    path: 'products/pistachio',
  },
  {
    src: '/assets/images/publish/products/taste-categories/nuts-category/Hazelnut.png',
    title: 'Hazelnut',
    category: 'Nuts',
    path: 'products/hazelnut',
  },
  {
    src: '/assets/images/publish/products/taste-categories/nuts-category/Chestnut.png',
    title: 'Chestnut',
    category: 'Nuts',
    path: 'products/shestnut',
  },
  {
    src: '/assets/images/publish/products/taste-categories/nuts-category/Peanut.png',
    title: 'Peanut',
    category: 'Nuts',
    path: 'products/peanut',
  },
  {
    src: '/assets/images/publish/products/taste-categories/nuts-category/Wallnut.png',
    title: 'Wallnut',
    category: 'Nuts',
    path: 'products/wallnut',
  },
];
const spiceCategoryData = [
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Black Cumin.png',
    title: 'Black Cumin',
    category: 'Spice',
    path: 'products/black-cumin',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Black Pepper.png',
    title: 'Black Pepper',
    category: 'Spice',
    path: 'products/black-pepper',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Chili.png',
    title: 'Chili',
    category: 'Spice',
    path: 'products/chili',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Cinnamon.png',
    title: 'Cinnamon',
    category: 'Spice',
    path: 'products/cinnamon',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Clove.png',
    title: 'Clove',
    category: 'Spice',
    path: 'products/clove',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Coconut.png',
    title: 'Coconut',
    category: 'Spice',
    path: 'products/coconut',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Cumin.png',
    title: 'Cumin',
    category: 'Spice',
    path: 'products/cumin',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Curry.png',
    title: 'Curry',
    category: 'Spice',
    path: 'products/curry',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Ginger.png',
    title: 'Ginger',
    category: 'Spice',
    path: 'products/ginger',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Mint.png',
    title: 'Mint',
    category: 'Spice',
    path: 'products/mint',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Oregano.png',
    title: 'Oregano',
    category: 'Spice',
    path: 'products/oregano',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Red Pepper.png',
    title: 'Red Pepper',
    category: 'Spice',
    path: 'products/red-pepper',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Rosemary.png',
    title: 'Rosemary',
    category: 'Spice',
    path: 'products/rosemary',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Sesame.png',
    title: 'Sesame',
    category: 'Spice',
    path: 'products/sesame',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Sumac.png',
    title: 'Sumac',
    category: 'Spice',
    path: 'products/sumac',
  },
  {
    src: '/assets/images/publish/products/taste-categories/spice-category/Turmeric.png',
    title: 'Turmeric',
    category: 'Spice',
    path: 'products/turmeric',
  },
];

export const productSubItemsList = [
  {
    title: 'Fresh Category',
    path: 'fresh-category',
    data: freshCategoryData,
    bg: 'bg-fresh-category',
  },
  {
    title: 'Frozen & Dried Category',
    path: 'frozen-dried-category',
    data: frozenDriedCategoryData,
    bg: 'bg-frozen-category',
  },
  {
    title: 'Process Category',
    path: 'process-category',
    data: processCategoryData,
    bg: 'bg-process-category',
  },
  {
    title: 'Nuts Category',
    path: 'nuts-category',
    data: nutsCategoryData,
    bg: 'bg-nuts-category',
  },
  {
    title: 'Spice Category',
    path: 'spice-category',
    data: spiceCategoryData,
    bg: 'bg-spices-category',
  },
];
