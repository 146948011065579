import React from 'react';
import WorkItem from './WorkItem';

const HomeLatestNewsContainer = ({ data, title, description }) => {
  return (
    <div
      className="portfolio-area ptb--75"
      data-black-overlay="6"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {data.map((value) => (
            <WorkItem
              key={value.title}
              category={value.category}
              image={value.image}
              title={value.title}
              path={value.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeLatestNewsContainer;
