import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle('active');
          this.classList.toggle('open');
        };
      }
    }
    const { color = 'default-color' } = this.props;
    const logoUrl = (
      <img
        className="logo-img"
        src="/assets/images/publish/logo/logo.png"
        alt="Gretra.co"
      />
    );
    // }

    return (
      <header className={`header-area formobile-menu header--transparent ${color}`}>
        <div
          className="header-wrapper"
          id="header-wrapper"
        >
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/overview">Company Overview</Link>
                    </li>
                    <li>
                      <Link to="/team">Executive Team</Link>
                    </li>
                    <li>
                      <Link to="/strategy">Strategy</Link>
                    </li>
                    <li>
                      <Link to="/stories">Stories</Link>
                    </li>
                    <li>
                      <Link to="/compliance">Ethic & Compliance</Link>
                    </li>
                    <li>
                      <Link to="/code-of-conduct">Code of Conduct</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-droupdown">
                  <Link to="/services">Products & Services</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/sustainability">Sustainability</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span
                onClick={this.CLoseMenuTrigger}
                className="closeTrigger"
              >
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
