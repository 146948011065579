import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Pesticides = () => {
  return (
    <PageWrapper pageTitle="Pesticides">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-pesticides"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Pesticides</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/products/agro-inputs/perticides/pesticides-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30 pb--50">
                        <h4 className="title">Integrated Pest Management</h4>
                        <p>
                          Gretra supports Integrated Agricultural Product Management and an important element of this
                          management, IPM (Integrated Pest Management). Sustainable use of Plant Protection Products is
                          an important pillar of sustainable agriculture and sustainable development. Gretra makes
                          long-term agreements with determined forecasts with Plant Protection companies and distributes
                          chemical, biological and organic plant protection materials to its partners all around the
                          Turkiye.
                        </p>
                      </div>
                      <div className="details mt_md--30 mt_sm--30 ">
                        <h4 className="title">Pest Categories</h4>
                        <ul className="liststyle">
                          <li>Organic</li>
                          <li>Biological</li>
                          <li>Chemical</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Pesticides;
