import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Blueberry = () => {
  return (
    <PageWrapper pageTitle="Blueberry">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-blueberry"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Blueberry</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/blueberry-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        {/* <h4 className="title">It’s very important to touch the right points.</h4> */}
                        <ul className='liststyle'>
                        <li>
                          Blueberry fruits have become a new source of income for entrepreneurs. There are even those who invest, bought the field, and start production in Turkiye.
                        </li>
                        <li>
                          Blueberries are a solid commercial product for fresh consumption and the processed fruit (frozen & dried & processed) industry.
                        </li>
                        <li>
                          Exports of Turkiye have already reached 22 countries from Turkiye.
                        </li>
                        <li>
                          Turkish Statistical Institute (TUIK) reports, approximately 2,500 tons of blueberry have been produced in an area of ​​4,200 da in Turkiye.
                        </li>
                        <li>
                          A total of over 500.000 $ of blueberry exports were made from Turkiye in 2020. In 2021, which we left behind, exports increased by 177% and reached 1.362.000 $.
                        </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Blueberry;
