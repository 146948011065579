import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const OrganisationalWorks = () => {
  return (
    <PageWrapper pageTitle="Organizational Works">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-organisational-works"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Organisational Works</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Experience is the key</h4>
                        <p>Gretra has very experienced expertise in building organizations.</p>
                        <p>
                          Our professional way of working, lead by sample management style, makes us leaders in
                          organization-building activities. Please ask our corporate references and projects.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/organisational-works/organisational-works-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default OrganisationalWorks;
