import React from 'react';
import GoogleMapReact from 'google-map-react';
import ContactTwo from '../elements/contact/ContactTwo';
import PageWrapper from '../components/PageWrapper';
// import BrandTwo from '../elements/BrandTwo';
import { location } from '../constants/common';
import ContactDetail from '../elements/contact/ContactDetail';
// import Breadcrumb from '../elements/common/Breadcrumb';

const AnyReactComponent = () => (
  <>
    <div className="pin bounce"></div>
    <div className="pulse"></div>
  </>
);

const Contact = () => {
  return (
    <PageWrapper pageTitle="Contact">
      {/* <Breadcrumb title="Contact" /> */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_contact"
        data-black-overlay="6"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Contact Page Area  */}
      <ContactDetail />
      <ContactTwo />
      {/* End Contact Page Area  */}

      {/* Start Contact Map  */}
      <div className="rn-contact-map-area position-relative">
        <div style={{ height: '650px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API }}
            defaultCenter={location}
            defaultZoom={location.defaultZoom}
          >
            <AnyReactComponent
              lat={location.lat}
              lng={location.lng}
              text="Gre-tra Co."
            />
          </GoogleMapReact>
        </div>
      </div>
      {/* End Contact Map  */}

      {/* <BrandTwo /> */}
    </PageWrapper>
  );
};
export default Contact;
