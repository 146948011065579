import React from 'react';

const ServiceItem = ({ column, path, icon, title, description }) => {
  return (
    <div className={`/${column}`}>
      <a href={path}>
        <div className="service service__style--2">
          <div className="icon">{icon}</div>
          <div className="content">
            <h3 className="title">{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ServiceItem;
