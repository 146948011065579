import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Biological = () => {
  return (
    <PageWrapper pageTitle="Biological">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-biological"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Biological</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/products/agro-inputs/biological/biological-2.jpg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30 pb--50">
                        <h4 className="title">Is Biological destiny?</h4>
                        <p>
                          We in Gretra work for enlarging our biological portfolio to have more options in future
                          healthy farming trends. Our Biopesticide portfolio include mycoinsecticides, plant-derived
                          oils and their extracts strains of bacteria and a number of other inorganic compounds.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Biological;
