import React from 'react';
import { Parallax } from 'react-parallax';
import BrandTwo from '../elements/BrandTwo';
import { serviceList, blogPostsList, slideList, latestNewsList } from '../constants/common';
import HomeServiceContainer from '../components/HomeServiceContainer';
import HomeLatestNewsContainer from '../components/HomeLatestNewsContainer';
import PageWrapper from '../components/PageWrapper';
import HomeNetworkContainer from '../components/HomeNetworkContainer';
import HomeTestimonialContainer from '../components/HomeTestimonialContainer';

// const sliderImage = 'https://trydo.rainbowit.net/assets/images/bg/bg-image-11.jpg';
const sliderImage = '/assets/images/publish/home/home.jpeg';

// const servicesBgImg = '/assets/images/bg/paralax/bg-image-2.jpg';
const servicesBgImg = '/assets/images/publish/home/services.jpg';

// const latestNewsBgImg = '/assets/images/bg/paralax/bg-image-3.jpg';
const latestNewsBgImg = '/assets/images/publish/home/latest-news.jpeg';

// const clientsBgImg = '/assets/images/bg/paralax/bg-image-4.jpg';
const clientsBgImg = '/assets/images/publish/home/clients.jpeg';

// const networkBgImg = '/assets/images/bg/paralax/bg-image-6.jpg';
const networkBgImg = '/assets/images/publish/home/network.jpeg';

// const testimonialBgImg = '/assets/images/bg/paralax/bg-image-7.jpg';
const testimonialBgImg = '/assets/images/publish/home/testimonials.jpeg';

// const blogsBgImg = '/assets/images/bg/paralax/bg-image-8.jpg';
const blogsBgImg = '/assets/images/publish/home/blog-posts.jpeg';

const Home = () => {
  return (
    <PageWrapper pageTitle="Great-Trade">
      {/* Start Slider Area   */}

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        <Parallax
          bgImage={sliderImage}
          strength={400}
        >
        {/* <video className="video" src="/assets/images/publish/home/video.mp4" autoPlay muted loop></video> */}
          {slideList.map((value, index) => (
            <div
              className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ''}
                      {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                      {value.description ? <p className="description">{value.description}</p> : ''}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-solid"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Parallax>
      </div>
      {/* End Slider Area   */}

      {/* Start Service Area  */}
      <Parallax
        className="rn-paralax-service"
        bgImage={servicesBgImg}
        strength={600}
      >
        <HomeServiceContainer
          title={'Our Services'}
          serviceList={serviceList}
        />
      </Parallax>
      {/* End Service Area  */}

      {/* Start Latest News Area  */}
      <Parallax
        className="rn-paralax-portfolio"
        bgImage={latestNewsBgImg}
        strength={400}
      >
        <HomeLatestNewsContainer
          title={'Latest News'}
          data={latestNewsList}
        />
      </Parallax>
      {/* End Latest News Area  */}

      {/* Start CounterUp Area */}
      <Parallax
        className="rn-counterup-area rn-paralax-counterup"
        bgImage={networkBgImg}
        strength={700}
      >
        <HomeNetworkContainer />
      </Parallax>
      {/* End CounterUp Area */}

      {/* Start Testimonial Area */}
      <Parallax
        className="rn-testimonial-area rn-paralax-testimonial  rn-testimonial-light"
        bgImage={testimonialBgImg}
        strength={700}
        data-black-overlay="5"
      >
        <HomeTestimonialContainer />
      </Parallax>
      {/* End Testimonial Area */}

      {/* Start Latest Blogs Area */}
      <Parallax
        className="rn-blog-area rn-testimonial-light"
        bgImage={blogsBgImg}
        strength={300}
        data-black-overlay="7"
      >
        <HomeLatestNewsContainer
          title={'Blog Posts'}
          data={blogPostsList}
        />
      </Parallax>
      {/* End Latest Blogs Area */}

      {/* Start Brand Area */}
      <Parallax
        className="rn-brand-area"
        bgImage={clientsBgImg}
        strength={900}
      >
        <div className="brand-wrapper ptb--120">
          <div className="container pb--120">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
      </Parallax>
      {/* End Brand Area */}
    </PageWrapper>
  );
};
export default Home;
