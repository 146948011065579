import React from "react";
import { Route } from "react-router-dom";
import AgronomyConsulting from '../pages/Services/AgronomyConsulting';
import BrandDevelopment from '../pages/Services/BrandDevelopment';
import MarketPenetration from '../pages/Services/MarketPenetration';
import Reporting from '../pages/Services/Reporting';
import Digitalization from '../pages/Services/Digitalization';
import OrganisationalWorks from '../pages/Services/OrganisationalWorks';

const routeConfigList = [
  {
    path: `${process.env.PUBLIC_URL}/agronomy-consulting`,
    render: () => <AgronomyConsulting />,
  },
  {
    path: `${process.env.PUBLIC_URL}/brand-development`,
    render: () => <BrandDevelopment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/market-penetration`,
    render: () => <MarketPenetration />,
  },
  {
    path: `${process.env.PUBLIC_URL}/organizational-works`,
    render: () => <OrganisationalWorks />,
  },
  {
    path: `${process.env.PUBLIC_URL}/digitalization`,
    render: () => <Digitalization />,
  },
  {
    path: `${process.env.PUBLIC_URL}/reporting`,
    render: () => <Reporting />,
  },
];

export const serviceDetailRoutes = routeConfigList.map((route) => {
  return (
    <Route exact key={route.path} path={route.path} render={route.render} />
  );
});
