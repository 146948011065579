import React from 'react';
import ServiceItem from './ServiceItem';

const HomeServiceContainer = ({ serviceList, title, description }) => {
  return (
    <div className="service-area ptb--120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {serviceList.map((service) => (
            <ServiceItem
              key={service.title}
              column={'col-lg-4 col-md-4 col-sm-6 col-12'}
              path={service.path}
              title={service.title}
              icon={service.icon}
              description={service.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeServiceContainer;
