import React from 'react';
import PageWrapper from '../components/PageWrapper';
import TeamOne from '../blocks/team/TeamOne';

const Team = () => {
  return (
    <PageWrapper
      title="Team"
      isBreadCrumbActive
      breadcrumbTitle="Team"
    >
      <main className="page-wrapper">
        <div className="rn-team-wrapper ptb--120 bg_color--1">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <h2>Executive Team</h2>
                  </div>
                </div>
              </div>
              <TeamOne
                column="col-lg-4"
                teamStyle=""
                item="3"
              />
            </div>
          </div>
        </div>
      </main>
    </PageWrapper>
  );
};

export default Team;
