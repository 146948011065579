import React from 'react';
import Breadcrumb from '../elements/common/Breadcrumb';
import CounterOne from '../components/CounterOne';
// import Testimonial from '../elements/Testimonial';
import PageWrapper from '../components/PageWrapper';

const About = () => {
  return (
    <PageWrapper pageTitle="About">
      <Breadcrumb title="About" />
      <div className="rn-about-area pt--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/publish/about/about-1.jpeg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About Us</h2>
                    <p className="description">
                      Gretra takes its name from the connection of two words: 'Great Trade'. From healthy eatings to
                      indulgent, tasty options, Gretra offers a broad range of products that will help you stand out in
                      the market.
                    </p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">What we do</h3>
                        <p>
                          We in Gretra, prioritize people's happiness, healthy eating and the environment. We offer our
                          business partners products produced with sustainable methods. *We grow your business with
                          Gretra in Turkiye, CIS Countries, Balkans, Middle East, and Africa.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Why us</h3>
                        <p>
                          To stay competitive in today’s agro industry, you need a partner who understands the
                          challenges of building and running a profitable business – as well as the constantly changing
                          tastes of your customers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row--35 ptb--90 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2>Smart, Strategic, Strong</h2>
                    <p className="description">
                      Gretra draws its strength from the experience, expertise and work discipline of its employees and
                      is deciduous to connect people by being a bridge between Asia and Europe. Gretra is aware that
                      Turkiye has a key geographic location with very young and talented people for quality, sustainable
                      & affordable supply.
                    </p>
                    <p className="description">
                      Its organization is growing day by day due to its employees working with European business
                      discipline and large networks.
                    </p>
                    <p className="description">
                      Gretra support many of the agro companies that thrive by collaborating to build better supply
                      chains, manage risk, develop new projects, and improve the quality of life for their consumers.
                    </p>
                    <p className="description">We think great, we plan great, we trade great!</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/publish/about/about-2.jpeg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
            <div className="rn-counterup-area bg_color--1 container">
              <CounterOne />
            </div>
            <div className="row row--35 ptb--120 align-items-center">
              <div className="col-lg-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2>Modern & Expert Perspective to Agronomy</h2>
                    <p className="description">
                      We provide sustainable solutions to all our business partners in the agriculture sector and supply
                      chain with our environmental and human health-based policies by supporting agricultural expertise
                      and the supply chain. We work with the best farms to serve top quality meals for consumers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="rn-testimonial-area bg_color--5 ptb--120 container">
        <Testimonial /> // TODO: keep for when there are testimonials.
      </div> */}
    </PageWrapper>
  );
};
export default About;
