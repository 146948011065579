import React from 'react';

const ColumnFull = ({ title, description }) => {
  return (
    <div className="rn-columns-area ptb--120 bg_color--5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="single-column">
              <h4 className="tilte">{title}</h4>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnFull;
