import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Seeds = () => {
  return (
    <PageWrapper pageTitle="Seeds">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-seeds"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Seeds</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/products/agro-inputs/seeds/seeds-2.jpg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">It’s very important to touch the right points.</h4>
                        <p>
                          Turkiye has small-scale seed firms which are adapting more quickly to changing conditions than
                          deep-rooted companies of developed countries. Agricultural production continues year-round due
                          to favorable climate The Domestic Seed Market is developing continuously because of Import &
                          Export New trends about healthy living increase vegetable & fruit demand The seed business has
                          a very dynamic structure closely related to world organizations
                        </p>
                        <p>
                          Gretra has a lot of experience and a very large-scale network in the seed business for
                          building organizations & brands, market penetration, project management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Seeds;
