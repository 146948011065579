import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Digitalization = () => {
  return (
    <PageWrapper pageTitle="Digitalization">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-digitalization"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Digitalization</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/digitalization/digitalization-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">
                          Digitalization in Agro Business has started the way for large transformation in the sector
                        </h4>
                        <p>
                          We in Gretra support our growers and make them to have ability of data driven decisions by
                          IOT, Sensors, Machine learning. Real-time monitoring combined with periodic satellite- and
                          weather-based updates are increasing our farm productivity.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Digitalization;
