import React from 'react';
import Testimonial from '../elements/Testimonial';

function HomeTestimonialContainer() {
  return (
    <div
      className="testimonial-area ptb--120"
      data-black-overlay="6"
    >
      <div className="container">
        <Testimonial />
      </div>
    </div>
  );
}

export default HomeTestimonialContainer;
