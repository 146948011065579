import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Gretra | {props.pageTitle} </title>
        <meta
          name="description"
          content="Gretra"
        />
      </Helmet>
    </React.Fragment>
  );
};

export default PageHelmet;
