import React from 'react';

const ProductList = ({ data }) => {
  return (
    <div className="row">
      {data
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map((product, index) => (
          <div
            className="col-lg-4 col-md-6 col-sm-6 col-12"
            key={index}
          >
            <div className="blog blog-style--1">
              <div className="thumbnail">
                <a href={`/${product.path}`}>
                  <img
                    className="w-100"
                    src={product.src}
                    alt={product.title}
                  />
                </a>
              </div>
              <div className="content">
                <p className="blogtype">{product.category}</p>
                <h4 className="title">
                  {/* <a href={`/${product.path}`}>{product.title}</a> TODO: revert this when you have blog posts. */}
                  <p>{product.title}</p>
                </h4>
                <div className="blog-btn">
                  {/* <a
                  className="rn-btn text-white"
                  href={`/${product.path}`}
                >
                  Read More
                </a> */}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default ProductList;
