import React from 'react';
import PageWrapper from '../../components/PageWrapper';

function AQuickLookToHazelnutInTurkiye() {
  return (
    <PageWrapper pageTitle="A Quick look to Hazelnut in Turkiye">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-hazelnut-in-turkiye"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Hazelnut in Turkiye</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-blog-details pt--70 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <h3>A Quick Look to Hazelnut in Turkiye</h3>
                  <p>
                    Turkiye is the largest producer of hazelnuts in the world, and the country's hazelnut production has
                    a significant impact on the global market. Here are a few key points to consider regarding hazelnut
                    production in Turkiye:
                  </p>
                  <div className="row sercice-details-content pb--30 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/hazelnut-in-turkiye-2.jpg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <ul className="liststyle">
                          <li>
                            Turkiye produces approximately 75% of the world's hazelnuts, with the majority of production
                            taking place in the Black Sea region. Tasty Turkish hazelnuts are preferred by many food
                            companies in all around the world.
                          </li>
                          <li>
                            Turkiye exports a significant amount of hazelnuts to countries around the world, including
                            the European Union, the United States, and Asia. Hazelnuts are used in a variety of food
                            products, including chocolate, confectionery, and baked goods.
                          </li>
                          <li>
                            The Turkish government has established strict quality control measures for hazelnut
                            production and export to ensure that Turkish hazelnuts meet international standards for food
                            safety and quality.
                          </li>
                          <li>
                            Because of the food safety and importance od the sustainable supply chain management, many
                            Turkish hazelnut producers prioritize sustainability in their production methods, including
                            using eco-friendly fertilizers, spray programs and reducing water waste through efficient
                            irrigation systems.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ul className="liststyle">
                    <li>
                      Trade agreements are very important for hazelnut supply chain. Turkiye has signed trade agreements
                      with several countries, including the European Union, to facilitate exports of hazelnuts and other
                      agricultural products.
                    </li>
                  </ul>
                  <br />
                  <p>
                    Overall, hazelnut production in Turkiye is characterized by high volume, quality, and sustainability,
                    as well as a focus on meeting the needs of both domestic and international markets. The country's
                    position as the largest producer of hazelnuts in the world and its favorable trade conditions make
                    it an important player in the global market for this commodity. Turkish producers targets to
                    increase added value products for food market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default AQuickLookToHazelnutInTurkiye;
