import React from 'react';
import data from './data';

const TeamOne = (props) => {
  return data.map((value, i) => (
    <div
      className="row"
      style={{ justifyContent: 'center' }}
      key={value.title}
    >
      <div className={`${props.column}`}>
        <div className={`team ${props.teamStyle}`}>
          <div className="thumbnail">
            <img
              src={`/assets/images/publish/team/team-${i + 1}.jpeg`}
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">{value.title}</h4>
            <p className="designation">{value.designation}</p>
          </div>
          <ul className="social-icon">
            {value.socialNetwork.map((social, index) => (
              <li key={index}>
                <a href={`${social.url}`}>{social.icon}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="pt--60">{value.summary}</div>
    </div>
  ));
};
export default TeamOne;
