import React from 'react';
import { Link } from 'react-router-dom';

const WorkItem = ({ title, path, image }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--40">
      <div className="portfolio">
        <div className="thumbnail-inner">
          <div className={`thumbnail`} style={{backgroundImage: `url(${image})`}}></div>
          <div className={`bg-blr-image`} style={{backgroundImage: `url(${image})`}}></div>
          
        </div>
        <div className="content">
          <div className="inner">
            <h4>
              <a href={path}>{title}</a>
            </h4>
            <div className="portfolio-button">
              <a
                className="rn-btn"
                href={path}
              >
                More Info
              </a>
            </div>
          </div>
        </div>
        <Link
          className="link-overlay"
          to={path}
        ></Link>
      </div>
    </div>
  );
};

export default WorkItem;
