import React from 'react';
import ContactForm from './ContactForm';

const ContactTwo = () => {
  return (
    <div className="rn-contact-page ptb--90 bg_color--1">
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us.</h2>
                <p className="description">Contact us description</p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  src="/assets/images/publish/contact/contact-2.jpg"
                  alt="contact"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactTwo;
