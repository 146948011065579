import React from 'react';
import ColumnFull from '../components/ColumnFull';
import PageWrapper from '../components/PageWrapper';

const Stories = () => {
  return (
    <PageWrapper
      pageTitle="Stories"
      isBreadCrumbActive
      breadcrumbTitle="Stories"
    >
      <ColumnFull
        title="Stories"
        description="Coming soon."
      />
    </PageWrapper>
  );
};

export default Stories;
