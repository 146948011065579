import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const RelationsBetweenTurkeyEu = () => {
  return (
    <PageWrapper pageTitle="Relations Between Turkiye and EU">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-relations-turkey-eu"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Relations Between Turkiye and EU</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-blog-details pt--70 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <h3>Relations between Turkiye and Europe in Agricultural Sector</h3>
                  <p>
                    Turkiye has a complex relationship with the European Union (EU) in the agricultural sector. Here are
                    a few key points to consider:
                  </p>
                  <div className="row sercice-details-content pb--30 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/relations-turkey-eu-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <ul className="liststyle">
                          <li>
                            Trade relations: Turkiye exports a significant amount of agricultural products to EU
                            countries, including fruits, vegetables, and other food products. At the same time, Turkiye
                            also imports agricultural goods from EU countries, including machinery, seeds, and
                            fertilizers.
                          </li>
                          <li>
                            Accession to the EU: Turkiye has been an official candidate for accession to the EU since
                            1999, but progress towards membership has been slow. If Turkiye were to become a member of
                            the EU, it would be subject to EU regulations and standards in the agricultural sector,
                            including rules on food safety, plant and animal health, and environmental protection.
                          </li>
                          <li>
                            Agricultural subsidies: The EU provides subsidies to its agricultural sector, which can make
                            it difficult for Turkish farmers to compete with their EU counterparts in the export market.
                            With this, quality production, cheap man power and new generation expert companies are
                            making competitive works. Many companies in EU Food sector benefit this competitive
                            companies as an alternative way for strenghting their supply chain and increasing profit.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ul className="liststyle">
                    <li>
                      Custom duties: There are custom duties applied to some of the agricultural products traded between
                      Turkiye and the EU, which can increase the cost of exports and imports.
                    </li>
                    <li>
                      Technical barriers: There are also technical barriers that can limit trade between Turkiye and the
                      EU in the agricultural sector, including different regulations, standards, and certification
                      requirements.
                    </li>
                  </ul>
                  <br/>
                  <p>
                    Overall, the relationship between Turkiye and EU countries in the agricultural sector is complex and
                    can be influenced by a variety of political, economic, and technical factors. However, despite these
                    challenges, trade in agricultural goods between the two sides continues to be significant, with
                    opportunities for growth and cooperation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default RelationsBetweenTurkeyEu;
