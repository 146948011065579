import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Element Layout
import PortfolioDetails from './elements/PortfolioDetails';
import BlogDetails from './elements/BlogDetails';

//constants
import { appRoutes } from './routes';

const Root = () => {
  return (
    <BrowserRouter basename={'/'}>
      <PageScrollTop>
        <Switch>
          {appRoutes}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details`}
            component={PortfolioDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetails}
          />
        </Switch>
      </PageScrollTop>
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
