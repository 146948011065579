import React from 'react';
import { Route } from 'react-router-dom';
import About from '../pages/About';
import Complience from '../pages/Complience';
import CodeOfConduct from '../pages/CodeOfConduct';
import Overview from '../pages/Overview';
import Stories from '../pages/Stories';
import Strategy from '../pages/Strategy';
import Team from '../pages/Team';

const routeConfigList = [
  {
    path: `${process.env.PUBLIC_URL}/about`,
    render: () => <About />,
  },
  {
    path: `${process.env.PUBLIC_URL}/overview`,
    render: () => <Overview />,
  },
  {
    path: `${process.env.PUBLIC_URL}/team`,
    render: () => <Team />,
  },
  {
    path: `${process.env.PUBLIC_URL}/strategy`,
    render: () => <Strategy />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories`,
    render: () => <Stories />,
  },
  {
    path: `${process.env.PUBLIC_URL}/compliance`,
    render: () => <Complience />,
  },
  {
    path: `${process.env.PUBLIC_URL}/code-of-conduct`,
    render: () => <CodeOfConduct />,
  },
];

export const aboutRoutes = routeConfigList.map((route) => {
  return (
    <Route
      exact
      key={route.path}
      path={route.path}
      render={route.render}
    />
  );
});
