import React from "react";
import { Route } from 'react-router-dom';
import Service from '../elements/Service';
import Product from '../pages/Product';
import { productList, agroInputList, tasteCategoryList, productSubItemsList } from '../constants/product';
import Seeds from '../pages/Products/Seeds';
import Fertilizers from '../pages/Products/Fertilizers';
import Pesticides from '../pages/Products/Pesticides';
import DigitalSolutions from '../pages/Products/DigitalSolutions';
import Organic from '../pages/Products/Organic';
import Biological from '../pages/Products/Biological';

const productItemsConfigList = productSubItemsList.map((item) => {
  return {
    path: `${process.env.PUBLIC_URL}/${item.path}`,
    render: () => (
      <Product
        title={item.title}
        data={item.data}
        bg={item.bg}
      />
    ),
  };
});

const routeConfigList = [
  {
    path: `${process.env.PUBLIC_URL}/products`,
    render: () => (
      <Service
        serviceList={productList}
        title="Products"
      />
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/agro-inputs`,
    render: () => (
      <Service
        serviceList={agroInputList}
        title="Agro Inputs"
        description=""
      />
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/taste-categories`,
    render: () => (
      <Service
        serviceList={tasteCategoryList}
        title="Taste Category"
        description=""
      />
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/seeds`,
    render: () => <Seeds />,
  },
  {
    path: `${process.env.PUBLIC_URL}/fertilizers`,
    render: () => <Fertilizers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pesticides`,
    render: () => <Pesticides />,
  },
  {
    path: `${process.env.PUBLIC_URL}/organic`,
    render: () => <Organic />,
  },
  {
    path: `${process.env.PUBLIC_URL}/biological`,
    render: () => <Biological />,
  },
  {
    path: `${process.env.PUBLIC_URL}/digital-solutions`,
    render: () => <DigitalSolutions />,
  },
  ...productItemsConfigList,
];

export const productRoutes = routeConfigList.map((route) => {
  return (
    <Route exact key={route.path} path={route.path} render={route.render} />
  );
});
