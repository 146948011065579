import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const AgronomyConsulting = () => {
  return (
    <PageWrapper pageTitle="Agronomy Consulting">
      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-agronomy-consulting"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Agronomy Consulting</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/agronomy-consulting/agronomy-consulting-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">How it works?</h4>
                        <p>
                          Gretra follows new trends, technologies and agricultural science very closely and gives
                          consultations to customers in a large portfolio with 25+ years of experience in agricultural
                          practice and 15+ years of experience in corporate management. After determining the needs and
                          expectations of customers, Gretra offers tailor-made solutions to customers.
                        </p>
                        <h4 className="title">Main Consultation Topics</h4>
                        <ul className="liststyle">
                          <li>Agro Technologies</li>
                          <li>Growing Trends</li>
                          <li>Branding</li>
                          <li>Marketing & Sales - PR Strategies</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}
    </PageWrapper>
  );
};
export default AgronomyConsulting;
