import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Breadcrumb from '../elements/common/Breadcrumb';

const Sustainability = () => {
  return (
    <PageWrapper pageTitle="Sustainability">
      <Breadcrumb title="Sustainability" />
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/about/supply-chain.jpeg"
                          alt="Sustainability"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Sustainable Supply Chains</h4>
                        <h6 className="title">Our Target is to make zero carbon shipping</h6>
                        <p>
                          Our purpose is sustainable transport. We care our environment and we have committed to work
                          for zero carbon shipping. We feel responsible to have safer and clean transportation in our
                          operations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Environment, Biodiversity and Climate Change</h4>
                        <h6 className="title">Our Target is to make zero carbon foot print</h6>
                        <p>
                          Gretra founded on the columns of sustainability, decarbonization, healthy & happy people. We
                          care and feel responsible for our world. We are committed to make improvements in carbon
                          intensity in our operations. We prefer environmentalist partners in our business. We are
                          committed to protecting environment and biodiversity by working closely with our suppliers to
                          minimize the impact of our operations on the ecosystem.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/about/environment.jpeg"
                          alt="Sustainability"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/about/people-safety.jpeg"
                          alt="Sustainability"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">People, safety and well-being</h4>
                        <h6 className="title">
                          We care people in our all operations. We feel responsible for our partners to work in safe and
                          good standards.
                        </h6>
                        <p>
                          We prioritize human! In Gretra, our first aim to have healthy, safe and joyful life in very
                          good mood. We work for improving life standards of our people, partners and of course
                          consumers.
                        </p>
                        <p>
                          We provide safe working conditions, Psychological well-being is just as important as physical
                          well-being for us. We provide flexible working hours, psychological well-being and health
                          support for our people.
                        </p>
                      </div>
                    </div>
                  </div>
                  <blockquote className="rn-blog-quote">
                    Gretra actively supports The Convention on Biological Diversity, Rio Declaration on Environment and
                    Development and the UN Framework Convention on Climate Change (UNFCCC).
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
export default Sustainability;
