import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Hazelnut = () => {
  return (
    <PageWrapper pageTitle="Hazelnut">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-hazelnut"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Hazelnut</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/hazelnut-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        {/* <h4 className="title">It’s very important to touch the right points.</h4> */}
                        <ul className="liststyle">
                          <li>Turkiye exported a total of 312,564 tons of hazelnuts to 127 countries in 2022.</li>
                          <li>
                            Turkiye exports hazelnut to countries such as mainly Italy, Germany, France, Switzerland,
                            Poland.
                          </li>
                          <li>Ordu is the province with the highest hazelnut production area with 2.3 million da.</li>
                          <li>Turkiye ranked first in world hazelnut production last years</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Hazelnut;
