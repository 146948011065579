import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const ExportImport = () => {
  return (
    <PageWrapper pageTitle="Export & Import">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-export-import"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Export & Import</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-blog-details pt--70 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner" >
                <h2 style={{textAling: "center; !important"}}>Vegetable & Fruit Export</h2>
                  <p>
                    Turkiye is one of the world's largest exporters of fruits and vegetables, with exports totaling
                    around $5 billion per year. The majority of Turkiye's fruit and vegetable exports go to countries in
                    Europe, but the country also exports to countries in the Middle East and North Africa.{' '}
                  </p>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/export-import-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        {/* <h4 className="title">It’s very important to touch the right points.</h4> */}
                        <h5>The mainly exporting crops of Turkiye are:</h5>
                          <li>
                            Citrus fruits: Oranges, lemons, and grapefruits are some of the top citrus exports from
                            Turkiye.
                          </li>
                          <li>
                            Tomatoes: Turkiye is one of the largest exporters of tomatoes in the world, with the majority
                            of exports going to Europe.
                          </li>
                          <li>
                            Fresh vegetables: Cucumbers, bell peppers, eggplants, and zucchini are among the fresh
                            vegetables exported from Turkiye.
                          </li>
                          <li>
                            Dried fruits: Fig, apricot, raisins, and prunes are some of the dried fruits that Turkiye is
                            known for exporting.
                          </li>
                          <li>
                            Nuts: Almonds, hazelnuts, and walnuts are among the most popular nut exports from Turkiye.
                          </li>
                          <li>Pomegranates: Turkiye is one of the largest exporters of pomegranates in the world.</li>
                      </div>
                    </div>
                  </div>
                  <p>
                    These are some of the main crops that Turkiye exports, but the country is also known for exporting a
                    variety of other fruits and vegetables, including melons, peaches, plums, cherries, and potatoes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ExportImport;
