import React, { Fragment } from 'react';
import { address, contactEmail, googleMapsLocationUrl, phone, socialShare } from '../../constants/common';
import { FiPhone, FiMail, FiChevronUp } from 'react-icons/fi';
import { ImLocation } from 'react-icons/im';
import ScrollToTop from 'react-scroll-up';

const Footer = () => {
  return (
    <Fragment>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <footer className="footer-area">
        <div className="footer-wrapper">
          <div className="row align-items-end row--0">
            <div className="col-lg-6">
              <div className="footer-left">
                <div className="inner">
                  <span>Ready To Do This</span>
                  <h2>
                    Let's get <br /> to work
                  </h2>
                  <a
                    className="rn-button-style--2"
                    href="/contact"
                  >
                    <span>Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="footer-right"
                data-black-overlay="6"
              >
                <div className="row">
                  {/* Start Single Widget  */}
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="footer-link">
                      <h4>Quick Link</h4>
                      <ul className="ft-link">
                        <li>
                          <a href="/products">Products</a>
                        </li>
                        <li>
                          <a href="/services">Services</a>
                        </li>
                        <li>
                          <a href="/about">About</a>
                        </li>
                        <li>
                          <a href="/stories">Stories</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Widget  */}
                  {/* Start Single Widget  */}
                  <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                    <div className="footer-link">
                      <h4>Contact Us</h4>
                      <ul className="ft-link">
                        <li>
                          <a href="mailto:info@gre-tra.com">
                            <FiMail />
                            {contactEmail}
                          </a>
                        </li>
                        <li>
                          <a href="tel:00905333000016">
                            <FiPhone /> {phone}
                          </a>
                        </li>
                        <li>
                          <a href={googleMapsLocationUrl}>
                            <ImLocation /> {address}
                          </a>
                        </li>
                      </ul>

                      <div className="social-share-inner">
                        <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                          {socialShare.map((val, i) => (
                            <li key={i}>
                              <a href={`${val.link}`}>{val.Social}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* End Single Widget  */}

                  <div className="col-lg-12">
                    <div className="copyright-text">
                      <p>Copyright © 2022 Rainbow-Themes. All Rights Reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};
export default Footer;
