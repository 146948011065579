import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';
import Breadcrumb from '../elements/common/Breadcrumb';

const PageWrapper = ({ pageTitle, isBreadCrumbActive, breadcrumbTitle = '', children }) => {
  const title = pageTitle ? `Gretra | ${pageTitle}` : 'Gretra Co.';
  return (
    <>
      <Helmet pageTitle={title} />
      <Header
        headertransparent="header--transparent header--fixed"
        colorblack="color--black"
        logoname="logo.png"
      />
      {isBreadCrumbActive && <Breadcrumb title={breadcrumbTitle} />}
      {children}
      <Footer />
    </>
  );
};

export default PageWrapper;
