import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { testimonialData } from '../constants/common';

const Testimonial = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Tabs>
          {testimonialData.map((author) => {
            return (
              <TabPanel key={author.name}>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>{author.quote}</p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>{author.name}</span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
            );
          })}
          <TabList className="testimonial-thumb-wrapper">
            {testimonialData.map((item) => {
              return (
                <Tab key={item.name}>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src={item.imagePath}
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
              );
            })}
          </TabList>
          ;
        </Tabs>
      </div>
    </div>
  );
};
export default Testimonial;
