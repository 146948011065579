import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Onion = () => {
  return (
    <PageWrapper pageTitle="Onion">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-onion"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Onion</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/onion-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        {/* <h4 className="title">It’s very important to touch the right points.</h4> */}
                        <ul className="liststyle">
                          <li>Dry onion production is increasing.</li>
                          <li>
                            Central Anatolia (Ankara, Amasya, Corum provinces) are at the top in production for long day
                            onions
                          </li>
                          <li>Hybrid F1 onion seed plantation is increasing day by day.</li>
                          <li>
                            Quality is increasing and Turkiye could be a second option for supply chain of European
                            market.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Onion;
