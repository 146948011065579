import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Citrus = () => {
  return (
    <PageWrapper pageTitle="Citrus">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-citrus"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Citrus</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/blog-posts/citrus-2.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        {/* <h4 className="title">It’s very important to touch the right points.</h4> */}
                        <ul className="liststyle">
                          <li>Turkiye has an important citrus potential in terms of both production and export.</li>
                          <li>
                            Turkiye exports citrus fruits to countries such as mainly Russia, Iraq, Ukraine, Romania,
                            Poland, and Bulgaria.
                          </li>
                          <li>In 2021, 1.9 million tons of citrus fruits were exported from Turkiye..</li>
                          <li>
                            This export of 45% is met from tangerines, 33% from lemons, 14% from oranges, and 8% from
                            grapefruit.
                          </li>
                          <li>
                            Mediterranean coast (Adana, Mersin, Antalya) is a very important location for citrus
                            production
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Citrus;
