import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Breadcrumb from '../elements/common/Breadcrumb';

const Overview = () => {
  return (
    <PageWrapper pageTitle="Company Overview">
      <Breadcrumb title="Company Overview" />
      <div className="rn-about-area pt--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/publish/about/growing-health.jpeg"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Growing Health</h2>
                    <p className="description">
                      We all understand more and more with each passing day that Agriculture is the most important job
                      in our world!
                    </p>
                    <p className="description">Our mottos are ‘Good Food Improves Your Mood' and 'Helthy & Tasty’. Good food brings you good mood! </p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Modern & Expert Perspective to Agronomy</h3>
                        <p>
                          We provide sustainable solutions to all our business partners in the agriculture sector and
                          supply chain with our environmental and human health-based policies by supporting agricultural
                          expertise and the supply chain. We work with the best farms to serve top quality meals for
                          consumers.
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Why us</h3>
                        <p>
                          To stay competitive in today’s agro industry, you need a partner who understands the
                          challenges of building and running a profitable business – as well as the constantly changing
                          tastes of your customers.
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row row--35 ptb--90 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2>We care for your Happiness!</h2>
                    <p className="subtitle">
                      Family Dinners create lovely moments, memories keep the family together. We care about family
                      happiness. We make people all around the world happy by bringing them tasty and healthy food. Hope
                      you enjoy it!
                    </p>
                    <p className="subtitle">
                      You learn a few things during 15 years in corporate business and 25 years in agricultural practice
                      with a large scale network. Today, we as Gretra would like to apply that experience to serve our
                      customers all around the world, but first Turkiye. We make an effort for Sustainable Farming
                      activities to keep the world in a better version.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/publish/about/we-care-for-happiness.jpeg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
export default Overview;
