import React from 'react';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';
import { address, contactEmail, phone } from '../../constants/common';

const ContactDetail = () => {
  return (
    <div className="rn-contact-top-area ptb--90 bg_color--5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="rn-address">
              <div className="icon">
                <FiHeadphones />
              </div>
              <div className="inner">
                <h4 className="title">Phone Number</h4>
                <p>
                  <a href={`tel:${phone}`}>{phone}</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
            <div className="rn-address">
              <div className="icon">
                <FiMail />
              </div>
              <div className="inner">
                <h4 className="title">Email Address</h4>
                <p>
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
            <div className="rn-address">
              <div className="icon">
                <FiMapPin />
              </div>
              <div className="inner">
                <h4 className="title">Location</h4>
                <p>{address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
