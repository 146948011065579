import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const BrandDevelopment = () => {
  return (
    <PageWrapper pageTitle="Brand Development">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-brand-development"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Brand Development</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">What's the plan?</h4>
                        <p>
                          Gretra supports its business partners to develop their brands. Brand development is the
                          process of creating and strengthening your professional services brand.
                        </p>
                        <h5 className="title">We use the ways below for developing brands:</h5>
                        <ul className="liststyle">
                          <li>
                            The first phase is getting to know you and your brand strategy right and aligned with your
                            business objectives.
                          </li>
                          <li>
                            Second is developing all the tools you will need to communicate the brand with your clients,
                            such as your strategy, sales channel, story of the brand & product and all digital marketing
                            tools.
                          </li>
                          <li>Finally, there is the phase of strengthening your newly developed or updated brand.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/brand-development/brand-development-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default BrandDevelopment;
