import React from 'react';
import { Route } from 'react-router-dom';
// import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import Home from '../pages/Home';
import Sustainability from '../pages/Sustainability';
import Error404 from '../pages/Error404';
import Service from '../elements/Service';
import { serviceList } from '../constants/common';

const contactRoute = (
  <Route
    exact
    path={`${process.env.PUBLIC_URL}/contact`}
    component={Contact}
  />
);

const sustainabilityRoute = (
  <Route
    exact
    path={`${process.env.PUBLIC_URL}/sustainability`}
    component={Sustainability}
  />
);
// TODO: uncomment for blog
// const blogRoute = (
//   <Route
//     exact
//     path={`${process.env.PUBLIC_URL}/blog`}
//     component={Blog}
//   />
// );

const serviceRoute = (
  <Route
    exact
    path={`${process.env.PUBLIC_URL}/services`}
    render={() => (
      <Service
        serviceList={serviceList}
        title={'Services'}
      />
    )}
  />
);

const homeRoute = (
  <Route
    exact
    path={`${process.env.PUBLIC_URL}/`}
    component={Home}
  />
);

const error404Route = (
  <Route
    path={`${process.env.PUBLIC_URL}/404`}
    component={Error404}
  />
);

export const menuRoutes = [
  homeRoute,
  serviceRoute,
  sustainabilityRoute,
  // TODO: uncomment for blog
  //  blogRoute,
  contactRoute,
  error404Route,
];
