import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Breadcrumb from '../elements/common/Breadcrumb';
import { blogPostsList, latestNewsList } from '../constants/common';
import HomeLatestNewsContainer from '../components/HomeLatestNewsContainer';
// import BLogList from '../elements/blog/BlogList';

const Blog = () => {
  return (
    <PageWrapper pageTitle="Blog">
      <Breadcrumb title={'Blog'} />
      <HomeLatestNewsContainer
        data={[...latestNewsList, ...blogPostsList]}
      />
      {/* <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          <BLogList data={[...latestNewsList, blogPostsList]}/>
          <div className="row mt--20">
            <div className="col-lg-12"> */}
              {/* Start Pagination Area */}
              {/* <Pagination /> */}
              {/* End Pagination Area */}
            {/* </div>
          </div>
        </div>
      </div> */}
    </PageWrapper>
  );
};
export default Blog;
