import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const DigitalSolutions = () => {
  return (
    <PageWrapper pageTitle="DigitalSolutions">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-digital-solutions"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Digital Solutions</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/products/agro-inputs/digital-solutions/digital-solutions-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30 pb--50">
                        <h4 className="title">Optimize the food ecosystem</h4>
                        <p>
                          Our digital services aim to optimize the food ecosystem with intensive use of information and
                          data-driven decisions.
                        </p>
                        <p>
                          For this purpose, we use digital agricultural solutions for the entire agricultural value
                          chain, based on new technologies such as remote sensing, machine learning, Internet of Things
                          and we establish effective distribution systems and business partnerships for the mass use of
                          these technologies.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default DigitalSolutions;
