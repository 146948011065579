import React from 'react';
import PageWrapper from '../components/PageWrapper';
import ProductList from '../components/ProductList';
// import Pagination from '../elements/common/Pagination';

const Product = ({ title, data }) => {
  return (
    <PageWrapper
      pageTitle={title}
      isBreadCrumbActive
      breadcrumbTitle={title}
    >
      <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          <ProductList data={data} />
          {/* <div className="row mt--20"> TODO: fix this. */}
          {/* <div className="col-lg-12">
              <Pagination />
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Product;
