import React from "react";
// import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

let data = [
  {
    images: '01',
    title: 'Enver Tekeli',
    designation: 'Founder',
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/in/enver-tekeli-059a9761/',
      },
    ],
    summary: (
      <>
        <p>
          He describes himself as an energetic leader who touches people's hearts and who is a positivist, an out of box
          thinker, a system and organization builder, a Sustainability - Green Deal - Environment, Human & Animal rights
          advocate.
        </p>
        <p>
          Enver founded Gretra in July 2022. He began his career in 2007 in a local agricultural input distributor
          company as a Sales Representative in Agricultural Capital City of Turkiye, Adana. He started from the bottom in
          his field of the agricultural sector. Later on, he took advantage of this first step from the bottom as a luck
          for building a broader vision, because he has been at each step of the career stairs in his profession. He
          later took responsibility for Technical Sales Engineering in Hektas T.A.S., which is one of the largest local
          Plant Protection companies in Turkiye. Later in his career, he took responsibility for BASF Regional Sales
          Executive in Adana, Turkiye for 6 years. After having enough experience in the Plant Protection sector, he
          decided to move to the Sustainability Manager position in Cargill. He built the Sustainability Department of
          Cargill Turkiye and managed operations in Turkiye and the Balkans. He took many business awards for Cargill for
          sustainability. He and his team produced the first certified Sustainable Glucose Syrup in Turkiye and certified
          sustainable sunflower in Romania. Later on, he was transferred to BAYER as a Country Sales & Marketing Manager
          of Bayer Vegetable Seeds Turkiye. He got promoted in Bayer after 2 years and was honored with the position of
          Managing Director of Turkiye and Azerbaijan. After Bayer sold the Vegetable Seeds Business to BASF, he
          continued his role as Managing Director and Country Marketing & Sales Manager positions together with BASF. He
          increased turnover more than 500% and enlarged his team by 150% in 5 years.
        </p>
        <p>
          He studied Agronomy at Wageningen University / Holland and has a Master's Degree from Cukurova University /
          Turkiye. He has a diverse agronomy perspective and a large network which he benefits to build his own
          organization nowadays.
        </p>
      </>
    ),
  },
  // {
  //   images: '02',
  //   title: 'Umit Abca',
  //   designation: 'International Operations Manager',
  //   socialNetwork: [
  //     {
  //       icon: <FaFacebookF />,
  //       url: '#',
  //     },
  //     {
  //       icon: <FaLinkedinIn />,
  //       url: '#',
  //     },
  //   ],
  //   summary: (
  //     <>
  //       <p>
  //         He describes himself as a relationship guru, large-scope problem fixer, unlimited positivist and humanist
  //         leader. He started his career as a financial advisor in 2000 after graduating Izmir Dokuz Eylül University.
  //         Later on, he decided to change his financial adviser to International Operations. He took an offer from
  //         Nunhems Tohumculuk A.S (Bayer Vegetable Seeds) and he accepted. He has been working for 18 years for Bayer
  //         Vegetable Seeds in different roles. He took responsibility for the Finance Department in 2004. He has
  //         supported company building Finance and Administration departments. Later on, he took responsibility for
  //         international operations and he started to build he Import&Export department of Bayer Vegetable Seeds. He got
  //         promotion and he took responsibility for Customer Service Lead in Turkiye and CIS countries. He supported a lot
  //         of his colleagues in European countries, especially Holland, Germany, Spain, Italy, France. He decided to
  //         leave the company and realize his start-up dream in August 2022. He is responsible for managing all
  //         international affairs and operations of Gretra.
  //       </p>
  //     </>
  //   ),
  // },
];

export default data;
