import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { counterData } from '../constants/common';

const CounterOne = () => {
  const [didViewCountUp, setdidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setdidViewCountUp(true);
    }
  };
  return (
    <div className="row">
      {counterData.map((value, index) => (
        <div
          className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
          key={index}
        >
          <h5 className="counter">
            <VisibilitySensor
              onChange={onVisibilityChange}
              offset={{ top: 10 }}
              delayedCall
            >
              <CountUp end={didViewCountUp ? value.countNum : 0} />
            </VisibilitySensor>
          </h5>
          <h4 className="description">{value.countTitle}</h4>
        </div>
      ))}
    </div>
  );
};
export default CounterOne;
