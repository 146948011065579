
import React from "react";
import { Route } from 'react-router-dom';
import Blueberry from "../pages/Blog/Blueberry";
import Citrus from "../pages/Blog/Citrus";
import Raisin from "../pages/Blog/Raisin";
import StoneFruit from "../pages/Blog/StoneFruit";
import Hazelnut from "../pages/Blog/Hazelnut";
import Onion from "../pages/Blog/Onion";
import Blog from "../pages/Blog";
import ImportanceOfArtichoke from "../pages/Blog/ImportanceOfArtichoke";
import AQuickLookToHazelnutInTurkiye from "../pages/Blog/AQuickLookToHazelnutInTurkiye";
import OnionGrowers from "../pages/Blog/OnionGrowers";
import RelationsBetweenTurkeyEu from "../pages/Blog/RelationsBetweenTurkeyEu";
import TurkeyBenefitsGeoPos from "../pages/Blog/TurkeyBenefitsGeoPos";
import ExportImport from "../pages/Blog/ExportImport";

const routeConfigList = [
  {
    path: `${process.env.PUBLIC_URL}/blog/`,
    render: () => <Blog/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blueberry`,
    render: () => <Blueberry/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/citrus`,
    render: () => <Citrus/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/raisin`,
    render: () => <Raisin/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/stone-fruit`,
    render: () => <StoneFruit/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/hazelnut`,
    render: () => <Hazelnut/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/onion`,
    render: () => <Onion/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/importance-of-artichoke`,
    render: () => <ImportanceOfArtichoke/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/hazelnut-in-turkey`,
    render: () => <AQuickLookToHazelnutInTurkiye/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/turkish-onion-growers-competing-with-eu`,
    render: () => <OnionGrowers/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/relations-between-turkey-europe`,
    render: () => <RelationsBetweenTurkeyEu/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/turkey-benefits-geographical-advantage`,
    render: () => <TurkeyBenefitsGeoPos/>,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/export-import`,
    render: () => <ExportImport/>,
  }
];

export const blogRoutes = routeConfigList.map((route) => {
  return (
    <Route exact key={route.path} path={route.path} render={route.render} />
  );
});