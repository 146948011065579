import React from 'react';
import { Route } from 'react-router-dom';
import { productRoutes } from './products';
import { serviceDetailRoutes } from './serviceDetails';
import { aboutRoutes } from './about';
import { menuRoutes } from './menu';
import { blogRoutes } from './blog';
import Error404 from '../pages/Error404';


export const appRoutes = [
  ...productRoutes,
  ...serviceDetailRoutes,
  ...blogRoutes,
  ...aboutRoutes,
  ...menuRoutes,
  <Route component={Error404} />,
];
