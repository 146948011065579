import React from 'react';
import { AiOutlinePieChart, AiOutlineFileDone, AiOutlineRocket, AiOutlineLaptop } from 'react-icons/ai';
import { GiFarmTractor, GiOrganigram } from 'react-icons/gi';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

export const socialShare = [
  {
    Social: <FaFacebookF />,
    link: 'https://business.facebook.com/latest/home?nav_ref=fb_web_pplus_settings_menu&asset_id=107393845435945',
  },
  {
    Social: <FaLinkedinIn />,
    link: 'https://www.linkedin.com/in/gretra-tar%C4%B1m-ticaret-limited-%C5%9Firketi-5a0595254/',
  },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/gretra.co/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/GretraCo' },
];

export const googleMapsLocationUrl = 'https://goo.gl/maps/VwGdFsjvCJKNua498';
export const address = 'Fener Mh. Bülent Ecevit Blv. Barış Apt. No:7 07160 Muratpaşa / Antalya';
export const phone = '+90 850 220 51 77';
export const contactEmail = 'info@gre-tra.com';
export const location = {
  lat: 36.858400431963936,
  lng: 30.749508295575566,
  defaultZoom: 16,
};

export const counterData = [
  {
    countNum: 24,
    countTitle: 'Countries',
    countDescription:
      'Canada, America, Denmark, Finland, Sweden, Switzerland, Norway, England, Netherlands, Germany, Belgium, France, Spain, Italy, Russia, Ukraine, Belarus, Azerbaijan, Uzbekistan, Turkmenistan, Iraq, Jordan, Qatar, Emirates',
  },
  {
    countNum: 162,
    countTitle: 'Agro Retailers',
    countDescription: 'Technical support service and input trade',
  },
  {
    countNum: 5670,
    countTitle: 'Farmers',
    countDescription: 'Input supply, contract farming, crop supply.',
  },
];

export const testimonialData = [
  {
    name: 'Alexander S.',
    quote:
      'I had some prejudice about Onion production & trade from Turkey. My opinion changed when I met with Gretra especially when I see their quality service.',
    imagePath: '/assets/images/publish/testimonials/alexander.jpeg',
  },
  {
    name: 'Guillaume L.',
    quote:
      'Gretra connect with us and we met about Blueberry. First trial was a kinda micro export but it was very good. We will continue next season',
    imagePath: '/assets/images/publish/testimonials/guillaume.jpeg',
  },
  {
    name: 'Olga S.',
    quote:
      'Highly recommended! We had lemon shortage. Met with Gretra via a friend offer. They fix our problem. You are perfect guys!',
    imagePath: '/assets/images/publish/testimonials/olga.jpeg',
  },
  {
    name: 'Altan K.',
    quote: 'Elma bahçemiz için gübreleme danışmanlık hizmeti alıyoruz memnunuz.',
    imagePath: '/assets/images/publish/testimonials/altan.jpeg',
  },
  {
    name: 'Unsal Y.',
    quote: 'Tarım teknolojileri ile ilgili yardımcı oldular. Ürünleri çok kaliteli.',
    imagePath: '/assets/images/publish/testimonials/unsal.jpeg',
  },
  {
    name: 'Maxim V.',
    quote:
      'I am from Russia. I know Gretra because of my friend. They did not sell tomato but help me to find true contact.',
    imagePath: '/assets/images/publish/testimonials/maxim.jpeg',
  },
];

export const blogContent = [
  {
    images: '01',
    title: ' Getting tickets to the big show',
    category: 'Development',
  },
  {
    images: '02',
    title: 'A big ticket gone to be an interesting ',
    category: 'Management',
  },

  {
    images: '03',
    title: 'The Home of the Future Could Bebes',
    category: 'Design',
  },
  {
    images: '04',
    title: ' Getting tickets to the big show',
    category: 'Development',
  },
  {
    images: '01',
    title: 'A big ticket gone to be an interesting ',
    category: 'Management',
  },
  {
    images: '02',
    title: 'The Home of the Future Could Bebes',
    category: 'Design',
  },
];

export const serviceList = [
  {
    icon: <GiFarmTractor />,
    title: 'Agronomy Consulting',
    path: '/agronomy-consulting',
  },
  {
    icon: <AiOutlineRocket />,
    title: 'Brand Development',
    path: '/brand-development',
  },
  {
    icon: <AiOutlinePieChart />,
    title: 'Market Penetration',
    path: '/market-penetration',
  },
  {
    icon: <GiOrganigram />,
    title: 'Organizational Works',
    path: '/organizational-works',
  },
  {
    icon: <AiOutlineLaptop />,
    title: 'Digitalization',
    path: '/digitalization',
  },
  {
    icon: <AiOutlineFileDone />,
    title: 'MarCom 5.0',
    path: '/reporting',
  },
];

export const blogPostsList = [
  {
    category: 'Fresh Fruits',
    title: 'Blueberry',
    path: '/blog/blueberry',
    image: '/assets/images/publish/blog-posts/blueberry.jpeg',
  },
  {
    category: 'Fresh Fruits',
    title: 'Citrus',
    path: '/blog/citrus',
    image: '/assets/images/publish/blog-posts/citrus.jpeg',
  },
  {
    category: 'Dried Fruits',
    title: 'Raisin',
    path: '/blog/raisin',
    image: '/assets/images/publish/blog-posts/raisin.jpeg',
  },
  {
    category: 'Fresh Fruits',
    title: 'Stone Fruit',
    path: '/blog/stone-fruit',
    image: '/assets/images/publish/blog-posts/stone-fruit.jpeg',
  },
  {
    category: 'Nuts',
    title: 'Hazelnut',
    path: '/blog/hazelnut',
    image: '/assets/images/publish/blog-posts/hazelnut.jpeg',
  },
  {
    category: 'Vegetables',
    title: 'Onion',
    path: '/blog/onion',
    image: '/assets/images/publish/blog-posts/onion.jpeg',
  },
];

export const latestNewsList = [
  {
    title: 'Export & Import',
    path: '/blog/export-import',
    image: '/assets/images/publish/blog-posts/export-import.jpeg',
  },
  {
    title: 'Turkiye benefits from geographical advantages in the agricultural sector',
    path: '/blog/turkey-benefits-geographical-advantage',
    image: '/assets/images/publish/blog-posts/turkey-benefits-geographical-advantage.jpeg',
  },
  {
    title: 'Relations between Turkiye and Europe in Agricultural Sector',
    path: '/blog/relations-between-turkey-europe',
    image: '/assets/images/publish/blog-posts/relations-between-turkey-europe.jpeg',
  },
  {
    title: 'Turkish onion growers are competing with EU growers in quality aspects',
    path: '/blog/turkish-onion-growers-competing-with-eu',
    image: '/assets/images/publish/blog-posts/turkish-onion-growers-competing-with-eu.jpeg',
  },
  {
    title: 'A quick look to Hazelnut in Turkiye',
    path: '/blog/hazelnut-in-turkey',
    image: '/assets/images/publish/blog-posts/hazelnut-in-turkiye.jpg',
  },
  {
    title: 'Importance of arthichoke consumption',
    path: '/blog/importance-of-artichoke',
    image: '/assets/images/publish/blog-posts/importance-of-artichoke.jpeg',
  }
];

export const slideList = [
  {
    textPosition: 'text-center',
    category: '',
    title: 'GREat-TRAde',
    description: 'Good food improves your mood.',
    buttonText: 'Explore',
    buttonLink: '/contact',
  },
];
