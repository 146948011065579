import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const MarketPenetration = () => {
  return (
    <PageWrapper pageTitle="Market Penetration">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-market-penetration"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Market Penetration</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/market-penetration/market-penetration-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">What's so special?</h4>
                        <p>Gretra has easy access to a wide range of services and products designed for the region.</p>
                        <p>
                          We respond more quickly and connect our partners with the right experts, decreasing costs and
                          business development & project times with our local offices and technical support teams.
                        </p>
                        <p>
                          We transparently supply information flow to the Point of Contact of our partners. Our Market
                          Development Service, Technical Support Teams and Local Operation offices accelerate market
                          penetration of your brand or products, bringing quick & efficient results. Gretra is one of
                          the most visionary and trustful business partners in the agriculture sector and serves its
                          customers of the best quality on every part of their commercial voyage.
                        </p>
                        {/* <h4 className="title">Main Consultation Topics</h4>
                        <ul className="liststyle">
                          <li>Agro Technologies</li>
                          <li>Growing Trends</li>
                          <li>Branding</li>
                          <li>Marketing & Sales - PR Strategies</li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}
    </PageWrapper>
  );
};

export default MarketPenetration;
