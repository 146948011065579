import React from 'react';
import CounterOne from './CounterOne';

function HomeNetworkContainer() {
  return (
    <div className="counterup-area ptb--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
              <h3>Our Network</h3>
            </div>
          </div>
        </div>
        <CounterOne />
      </div>
    </div>
  );
}

export default HomeNetworkContainer;
