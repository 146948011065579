import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Reporting = () => {
  return (
    <PageWrapper pageTitle="Reporting">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-reporting"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">MarCom 5.0</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <h3 className="title">Marketing & Communication 5.0</h3>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <ul className="liststyle">
                          <li>
                            <h5>Social Media Management</h5>
                            <li>Basic</li>
                            <li>Professional</li>
                          </li>
                          <li>
                            <h5>Graphic Design Services</h5>
                            <li>Logo, Corporate Identity, Brand Book Design</li>
                            <li>Packaging, Advertising & Banner, Content Design</li>
                            <li>Post & Poster, Catalog, Presentation and Infographic Design</li>
                          </li>
                          <li>
                            <h5>Software</h5>
                            <li>Corporate Website & E-Commerce Website Design</li>
                            <li>Web Software</li>
                            <li>Domain & Hosting Services</li>
                            <li>Mobile Applications</li>
                          </li>
                          <li>
                            <h5>Printing</h5>
                            <li>Business Card Design</li>
                            <li>Corporate & Promotional Product Prints</li>
                            <li>Flyers & Brochures & Catalogue & Magazine Prints</li>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/reporting/reporting-3.jpeg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/services/reporting/reporting-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <ul className="liststyle">
                          <li>
                            <h5>Production</h5>
                            <li>Photo & Video Shooting (Product, Promotion, Model, Campaign, Story)</li>
                            <li>Video Editing, Dubbing, Animation,</li>
                            <li>Logo Intro Work</li>
                            <li>Jingle & Advertising Music</li>
                            <li>Video, GIF Ads.</li>
                          </li>
                          <li>
                            <h5>Advertising</h5>
                            <li>Google, Facebook, Instagram, Youtube, Linkedin Ads</li>
                            <li>SEO Management</li>
                          </li>
                          <li>
                            <h5>Event Management</h5>
                            <li>Event Planning</li>
                            <li>Field Day Planning & Organization</li>
                          </li>
                          <li>
                            <h5>Content Creation & Translation</h5>
                            <li>Translation Support</li>
                            <li>Website Content, Blogging, Social Media Content Authoring</li>
                            <li>Brand Name, Motto, Story Creation</li>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Reporting;
