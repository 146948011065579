import React from 'react';
import PageWrapper from '../../components/PageWrapper';

const Fertilizers = () => {
  return (
    <PageWrapper pageTitle="Fertilizers">
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg-fertilizers"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Fertilizers</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30 pb--50">
                        <h4 className="title">Big opportunity for new technology and special fertilizers in Turkiye</h4>
                        <p>
                          The fertilizer consumption in Turkiye, which has had an increasing trend over the years,
                          cannot meet needs through production. Turkiye is foreign-dependent in terms of fertilizer raw
                          material source, and supplies more than 90% of raw materials by import. Fertilizer use in
                          Turkiye is an average of 5-6 million tons per year.
                        </p>
                      </div>
                      <div className="details mt_md--30 mt_sm--30 ">
                        <h5 className="title">
                          Gretra has 3 local offices which are located in the agricultural centers of Turkiye. We serve
                          customers in the true places and on time with our team.
                        </h5>
                        <ul className="liststyle">
                          <li>Antalya / West Mediterranean - Greenhouse & Protected Crops Center</li>
                          <li>Adana / East Mediterranean - Orchards, Open Field Crops, Cereals Center</li>
                          <li>Samsun / Black-sea - Open Field Crops, Hazelnut and Tea Center</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/products/agro-inputs/fertilizers/fertilizers-2.png"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Fertilizers;
