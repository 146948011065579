import React from 'react';
import PageWrapper from '../components/PageWrapper';

const Strategy = () => {
  return (
    <PageWrapper
      pageTitle="Strategy"
      isBreadCrumbActive
      breadcrumbTitle="Strategy"
    >
      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                {/* <h2 className="title">Trust us, we are experts.</h2> TODO: make this centered. */}
                <div className="inner">
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/strategy/strategy-1.jpg"
                          alt="Strategy"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Straight to the networking</h4>
                        <h5 className="title">With Gretra's wide network, your business will grow rapidly.</h5>
                        <p>
                          With Gretra's wide network, your business will grow rapidly. We connect producers and
                          suppliers of fruits and vegetables around the globe through organization, origination,
                          processing, trading, and distribution, as well as offering a range of consulting, input
                          supply, digitalization, farmer services and risk management solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Guidence & Coaching</h4>
                        <h5 className="title">
                          Gretra supports you in building a long-term partnership to grow your business.
                        </h5>
                        <p>
                          Gretra has easy access to a wide range of services and products designed for the region. We
                          respond more quickly and connect our partners with the right experts, decreasing costs and
                          business development & project times with our local offices and technical support teams. We
                          transparently supply information flow to the Point of Contact of our partners. Our Market
                          Development Service, Technical Support Teams and Local Operation offices accelerate market
                          penetration of your brand or products, bringing quick & efficient results.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/strategy/strategy-2.jpg"
                          alt="Strategy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/publish/strategy/strategy-3.jpg"
                          alt="Strategy"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">Experience based insights & Game plan</h4>
                        <h5 className="title">We took values from 50 years a commercial family business.</h5>
                        <p>
                          Our agronomy insight, local market experience, technical experience of our teams and
                          commitment to our business & customers bring results in our projects in an effective way. We
                          have commercial contact for all solution offerings, contact management, pricing, service
                          details who understands your business, expectations and needs, who can match you with
                          experienced technical and commercial contacts when you need.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}
    </PageWrapper>
  );
};

export default Strategy;
