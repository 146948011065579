import React from 'react';
import PageWrapper from '../components/PageWrapper';

const CodeOfConduct = () => {
  return (
    <PageWrapper
      pageTitle="Code of Conduct"
      isBreadCrumbActive
      breadcrumbTitle="Code of Conduct"
    >
      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <h2>Gretra Code of Conduct</h2>
                  <p>
                    Gretra’s Code of Conduct underlines our company’s compliance and ethical standards for executing
                    business with the world and serves as guide for employees when they face dilemmas where the true
                    choice is not clear. Our code of conduct is grounded in our Guiding Principles.
                  </p>
                  {/* <blockquote className="rn-blog-quote">
                    Gretra is an amazing company and we'd absolutely recommend anyone who is thinking about working with
                    Gretra because people who work there is awesome and they care about what they do.
                  </blockquote> */}
                  <div className="blog-single-list-wrapper d-flex flex-wrap">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/publish/about/transparent.jpg"
                        alt="BLog Images"
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Our Guiding Principles</h4>
                      <h6 className="title">We in Gretra obey the law</h6>
                      <p>
                        Obeying the law is the foundation on which our reputation and Guiding Principles are built. As a
                        young organization of world we privileged to do business all over the world, we feel the
                        responsibility to comply with all of the laws that apply to our businesses.
                      </p>
                      <h6 className="title">We in Gretra do business with integrity</h6>
                      <p>
                        We pride to do our business with integrity. We compete strong, but do so ethically and fairly.
                        We do not offer or accept inappropriate gifts or bribe and we comply with the laws, rules and
                        regulations that support fair competition and integrity in the agricultural market.
                      </p>
                      <h6 className="title">We in Gretra keep accurate records</h6>
                      <p>
                        Honest and accurate records are critical to making true business decisions and keep the
                        integrity of our financial reporting. Our business information must reflect the true nature of
                        our transactions.
                      </p>
                    </div>
                  </div>
                  <div className="blog-single-list-wrapper d-flex flex-wrap">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/publish/about/diversity.jpeg"
                        alt="BLog Images"
                      />
                    </div>
                    <div className="content">
                      <h6 className="title">We in Gretra honor our business obligations</h6>
                      <p>
                        Our business relationships are settled in mutual trust. We build and maintain the trust and
                        confidence of our employees, suppliers, customers and other business partners by communicating
                        honestly, respecting information entrusted to us and standing behind our commitments.
                      </p>
                      <h6 className="title">We in Gretra treat people with respect and dignity</h6>
                      <p>
                        We achieve our goals through our people. We provide a safe and happy workplace and value the
                        unique contributions of our team, enabling those who support our goals to achieve their own
                        individual potential.
                      </p>
                      <h6 className="title">We in Gretra are committed to being a responsible global citizen</h6>
                      <p>
                        Our global operations bring responsibility to understand and manage our role. We support the
                        protection of human rights, maintain strict environmental and food safety standards within our
                        operations and share our more than 20 years corporate knowledge and experience to help meet
                        economic and social challenges.
                      </p>
                    </div>
                  </div>
                  <blockquote className="rn-blog-quote">
                    Gretra works in line with international standards as well as the Environment, Human Rights, Labor,
                    and Anti-Corruption principles of UNGC.
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Details */}
    </PageWrapper>
  );
};
export default CodeOfConduct;
