import React from 'react';
import PageWrapper from '../components/PageWrapper';

const Complience = () => {
  return (
    <PageWrapper
      pageTitle="Ethic & Complience"
      isBreadCrumbActive
      breadcrumbTitle="Ethic & Complience"
    >
      {/* Start Blog Details */}
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-wrapper">
                <div className="inner">
                  <h2>Business Ethic and Compliance</h2>
                  <div className="blog-single-list-wrapper d-flex flex-wrap">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/publish/about/ethic-complience.jpeg"
                        alt="BLog Images"
                      />
                    </div>
                    <div className="content">
                      <blockquote className="rn-blog-quote">
                        We believe that doing the right thing sets the foundation for stabil long-term success.
                      </blockquote>
                      <p>
                        Trust is the basis of a strong relationship. Strong relationships are the first step towards
                        sustainable long-term trade. As members of a more than 50-year trade family, we all are
                        committed in Gretra to conducting business ethnically and responsibly, to honoring our
                        obligations and to treating everyone with respect and dignity. We have no tolerance for bribery
                        and other corrupt practices. We continue to advocate for increased transparency and
                        accountability and reduced corruption across the agricultural industry, Our employees,
                        suppliers, customers, partners, shareholders and communities count on us to uphold this
                        commitment and we are aware that Gretra’s continued success depends on it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
export default Complience;
