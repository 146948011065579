import React from 'react';

const BrandTwo = () => {
  return (
    <div className="rn-brand-area brand-separation bg_color--5 ptb--120 container">
      <ul className="brand-style-2">
        <li>
          <img
            src="/assets/images/publish/brand/brand-01.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-02.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-03.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-04.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-05.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-06.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-07.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img
            src="/assets/images/publish/brand/brand-08.png"
            alt="Logo Images"
          />
        </li>
      </ul>
    </div>
  );
};
export default BrandTwo;
